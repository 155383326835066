<template>
  <div class="modal">
    <div class="modal-bg" @click="discard(false)"></div>

    <div class="modal-block" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-else>
      <div class="modal-header d-block">
        <h3>Download</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="d-flex align-items-center border-bottom py-3" @click="toggleCheckboxAllItem">
            <img src="@/assets/icons/bold-checkbox-checked.svg" alt="download" class="icon me-2 opacity-05"
                 v-if="selectedItems.length >= docs.length">
            <img src="@/assets/icons/bold-checkbox-partial-selected.svg" alt="download" class="icon me-2 opacity-05"
                 v-else-if="selectedItems.length < docs.length && selectedItems.length !== 0">
            <img src="@/assets/icons/bold-checkbox-unchecked.svg" alt="download" class="icon me-2 opacity-05"
                 v-else>
            <span class="text-muted font-weight-normal">Select all: {{ selectedItems.length }}</span>
          </div>
          <div v-for="(doc, index) in docs" :key="index"
               class="d-flex align-items-center justify-content-between border-bottom py-3">
            <div class="d-flex align-items-center">
              <img src="@/assets/icons/bold-checkbox-unchecked.svg" alt="download" class="icon me-2 opacity-05"
                   @click="toggleCheckboxItem(doc, index)" v-if="!isSelected(doc)">
              <img src="@/assets/icons/bold-checkbox-checked.svg" alt="download" class="icon me-2 opacity-05"
                   @click="toggleCheckboxItem(doc, index)" v-else>
              <v-avatar :username="doc.name" :size="36" rounded/>
              <div class="ms-3">
                <span>{{ doc.name }}</span>
                <span class="text-muted ms-2">{{ doc.email }}</span>
              </div>
            </div>
            <div class="text-muted ms-2">
              {{ doc.docsTotal }} Document<span v-if="doc.docsTotal > 1">s</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button class="btn btn-primary px-5 me-2" type="button" @click="download">Download</button>
        <button class="btn btn-cancel px-5" type="button" @click="discard(false)">Discard</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadDocumentsModal",
  props: {docs: {type: Array, required: false}},
  data() {
    return {
      loading: false,
      selectedItems: []
    }
  },
  methods: {
    isSelected(obj) {
      return this.selectedItems.indexOf(obj) !== -1
    },
    toggleCheckboxItem(item) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id)
      } else {
        this.selectedItems.push(item)
      }
    },
    toggleCheckboxAllItem() {
      if (this.selectedItems.length > 0) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.docs
      }
    },
    getSelectedIds() {
      return this.selectedItems.map((item) => {
        return item.id
      })
    },
    download() {
      let queryParams = new URLSearchParams({});

      for (let id of this.getSelectedIds()) {
        queryParams.append('ids[]', id)
      }

      this.goto(this.backendUrl('/mlo/secure_inbox/download_archive?' + queryParams.toString()))
    },
    discard() {
      this.$emit('close', {
        propertyName: 'isOpenDownloadDocumentsModal',
        updateList: false
      })
    }
  },
  beforeMount() {
    this.selectedItems = this.docs
  }
}
</script>
<style type="scss" scoped>
.modal .modal-body-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
</style>
