<template>
  <div id="requestDocumentsModal" class="modal">
    <div class="modal-bg" @click="discard(false)"></div>

    <div v-if="ui.loading" class="modal-block">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form v-else class="modal-block">
      <div class="modal-header d-block">
        <h3>Request Documents</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div :class="[ borrowerSelected && !missingBorrowerEmail ? 'ms-3' : '' ]">
            <div v-if="borrowerSelected && !missingBorrowerEmail"
                 class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <v-avatar :size="36" rounded username="John Rmith"/>
                <div class="ms-3">
                  <div class="text-muted text-">Borrower</div>
                  <div>
                    <span>{{ borrower.name }}</span>
                    <span class="ms-2 text-muted">{{ borrower.email }}</span>
                  </div>
                </div>
              </div>
              <img alt="Edit" class="icon me-2" src="@/assets/icons/edit.svg" @click="toggleInput(null)">
            </div>
            <div v-else>
              <label>Input Borrower First and Last Name or Select from List:</label>
              <multiselect v-model="borrower"
                           :option-height="104"
                           :options="borrowers"
                           :searchable="true"
                           :show-labels="false"
                           :taggable="true"
                           label="name"
                           placeholder="Enter Borrower Name"
                           tag-placeholder="Press Enter to Save Name and Add Email."
                           track-by="name"
                           @select="toggleInput"
                           @tag="handleBorrower">
                <template slot="singleLabel" slot-scope="props">
                  <div>{{ props.option.name }}</div>
                </template>
                <template slot="option" slot-scope="props" class="d-flex align-items-center">
                  <v-avatar :size="36" :username="props.option.name" rounded/>
                  <div>
                    <div>{{ props.option.name }}</div>
                    <div>{{ props.option.email }}</div>
                  </div>
                </template>
              </multiselect>
              <div v-if="missingBorrowerEmail" class="col-md-6 mt-3">
                <label for="borrowerName">Borrower Email</label>
                <input id="borrowerName" v-model="borrower.email" class="form-control" type="email">
                <span v-for="(error, index) in getError('email', errors)" :key="index" class="error-msg">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Select documents</div>
          <div class="search-wrapper">
            <img alt="Icon search" src="@/assets/icons/icon-search.svg">
            <input v-model="query" aria-label="=Search Document" placeholder="Search Document" type="text">
          </div>
          <div v-for="(doc, index) in filteredList" :key="index"
               class="d-flex align-items-center justify-content-between border-bottom pb-2 pt-2">
            <div class="d-flex align-items-center" @click="toggleCheckboxItem(doc)">
              <img v-if="isSelected(doc)" alt="checked" class="icon me-2"
                   src="@/assets/icons/bold-blue-checkbox-checked.svg">
              <img v-else alt="unchecked" class="icon me-2 opacity-05" src="@/assets/icons/bold-blue-checkbox-unchecked.svg">
              <div class="cursor-pointer">{{ doc.name }}</div>
            </div>
            <img v-if="doc.isCustom" alt="Delete" class="icon me-2 opacity-05"
                 src="@/assets/icons/delete.svg" @click="deleteCustomDoc(doc.id)">
          </div>
          <div v-if="addNewDoc">
            <div class="col-md-6 mt-3">
              <label for="name">Name</label>
              <input id="name" v-model="customDocument.name" class="form-control w-100" type="text">
            </div>
            <div class="col-md-12 mt-3">
              <label for="description">Description</label>
              <textarea id="description"
                        v-model="customDocument.description"
                        class="form-control" cols="30"
                        placeholder="(Optional) Will be shown to borrower in email"
                        rows="4">
              </textarea>
            </div>
            <button :disabled="ui.addCustomDocs" class="btn bg-green px-5 me-3 mt-3" type="button"
                    @click="storeCustomDocument">
              <span v-if="ui.addCustomDocs"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    role="status"></span>
              <span v-else>Add to list</span>
            </button>
            <button class="btn btn-outline-dark px-5 me-3 mt-3" type="button" @click="addNewDoc = false">Discard
            </button>
          </div>
          <div v-else class="d-flex align-items-center mt-3" @click="addNewDoc = true">
            <img alt="Delete" class="ms-2 cursor-pointer" src="@/assets/icons/icon-add blue.svg">
            <div class="ms-3 text-blue cursor-pointer">Add Custom Document</div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Request Documents" action="secondary-default" @click-btn="requestDocuments" :loading="ui.request" :disabled="ui.request" />
        <base-button title="Discard" action="secondary" @click-btn="discard(false)" class="ms-2" />
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import BaseButton from '../../components/BaseButton.vue'

export default {
  name: "RequestDocumentsModal",
  components: {Multiselect, BaseButton},
  data() {
    return {
      ui: {
        loading: false,
        request: false,
        addCustomDocs: false
      },
      selectBorrower: true,
      borrowerSelected: false,
      missingBorrowerEmail: false,
      borrower: {
        email: null,
        name: null
      },
      borrowers: [],
      documents: [],
      customDocument: {
        name: '',
        description: ''
      },
      addNewDoc: false,
      selectedDocuments: [],
      query: '',
      errors: []
    }
  },
  methods: {
    isSelected(obj) {
      return this.selectedDocuments.indexOf(obj) !== -1
    },
    toggleCheckboxItem(item) {
      if (this.isSelected(item)) {
        this.selectedDocuments = this.selectedDocuments.filter((i) => i.name !== item.name)
      } else {
        this.selectedDocuments.push(item)
      }
    },
    getSelectedIds() {
      return this.selectedDocuments.map((item) => {
        return item.id
      })
    },
    toggleInput(option) {
      if (option === null) {
        this.borrower = {}
        this.borrowerSelected = !this.borrowerSelected
      } else {
        this.ui.loading = true;
        this.$http.get(`/api/v1/loans/lendingpad/autofill/borrower/${option.loanId}/secureinbox`)
          .then((res) => {
            if (res.data.email) {
              option.email = res.data.email;
            }
            this.borrower = option
            this.missingBorrowerEmail = this.borrower.name && !this.borrower.email
            this.borrowerSelected = !this.missingBorrowerEmail
          })
          .finally(() => {
            this.ui.loading = false;
          })
          .catch()
      }
    },
    handleBorrower(val) {
      let type = typeof val

      if (type === 'string') {
        this.borrower = {}
        this.borrower.email = null
        this.borrower.name = val
        this.missingBorrowerEmail = true
      } else {
        this.borrower = val
      }
    },
    getFormData() {
      this.ui.loading = true;
      this.$http.get('/api/v1/secure_inbox/request-docs')
        .then((res) => {

          for (const [key, doc] of Object.entries(res.data['documents'])) {
            let document = {
              id: key,
              name: doc.name,
              description: doc.description,
              isCustom: false,
              checked: doc.checked
            }

            this.documents.push(document)
            if (document.checked) {
              this.selectedDocuments.push(document)
            }
          }

          res.data['customDocuments'].forEach((doc) => {
            this.documents.push({
              id: doc.id,
              name: doc.name,
              description: doc.description,
              isCustom: true,
              checked: false
            })
          })

          res.data.borrowers.forEach((borrower) => {
            let data = {
              loanId: borrower.loanId,
              email: borrower.email,
              name: borrower.name
            };

            this.borrowers.push(data)
          })

          this.ui.loading = false
        })
        .catch()
    },
    requestDocuments() {
      this.ui.request = true
      this.$http.post('/api/v1/documents/request-docs', {
        docs: this.selectedDocuments,
        email: this.borrower.email,
        name: this.borrower.name
      })
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.discard()
        })
        .catch((err) => {
          this.errors = err.response.data.errors
        })
        .finally(() => {
          this.ui.request = false
        })
    },
    toggleNewDocForm() {
      this.customDocument.name = ''
      this.customDocument.description = ''
      this.addNewDoc = !this.addNewDoc
    },
    storeCustomDocument() {
      this.ui.addCustomDocs = true
      this.$http.post('/api/v1/documents/custom', {
        name: this.customDocument.name,
        description: this.customDocument.description
      })
        .then((res) => {
          let doc = res.data
          this.documents.push({
            id: doc.id,
            name: doc.name,
            description: doc.description,
            isCustom: true,
            checked: false
          })

          this.toggleNewDocForm()
        })
        .catch(() => {
        })
        .finally(() => {
          this.ui.addCustomDocs = false
        })
    },
    deleteCustomDoc(id) {
      this.$http.delete(`/api/v1/documents/custom/${id}`)
        .then(() => this.documents = this.documents.filter((item) => item.id !== id))
        .catch()
    },
    discard() {
      this.$emit('close', {
        propertyName: 'isOpenRequestDocumentsModal',
        updateList: false
      })
    }
  },
  computed: {
    filteredList() {
      return this.documents.filter(doc => {
        return doc.name.toLowerCase().includes(this.query.toLowerCase())
      })
    }
  },
  beforeMount() {
    this.getFormData()
  }
}
</script>
<style lang="scss">
#requestDocumentsModal {
  .icon {
    opacity: 1;
  }
  .modal-body-container {
    padding-bottom: 20px;
    margin-bottom: 20px;

    .multiselect {
      max-width: 400px;
    }
  }

  .multiselect__option {
    display: flex !important;
    align-items: center !important;

    div {
      margin-right: 10px;
    }
  }
}

</style>
