<template>
  <div class="modal">
    <div class="modal-bg" @click="discard(false)"></div>

    <div class="modal-block" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-else>
      <div class="modal-header d-block">
        <h3>Assign to Loans</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="d-flex align-items-center border-bottom py-3">
            <img src="@/assets/icons/bold-checkbox-checked.svg" alt="download" class="icon me-2 opacity-05"
                 v-if="selectedItems.length >= docs.length">
            <img src="@/assets/icons/bold-checkbox-partial-selected.svg" alt="download" class="icon me-2 opacity-05"
                 v-else-if="selectedItems.length < docs.length && selectedItems.length !== 0">
            <img src="@/assets/icons/bold-checkbox-unchecked.svg" alt="download" class="icon me-2 opacity-05"
                 v-else>
            <span class="text-muted font-weight-normal">Select all: {{ selectedItems.length }}</span>
          </div>
          <div v-for="(doc, index) in selectedItems" :key="index"
               class="d-flex align-items-center justify-content-between border-bottom py-3">
            <div class="d-flex align-items-center">
              <img src="@/assets/icons/bold-checkbox-checked.svg" alt="archive" class="icon me-3 opacity-05">
              <v-avatar :username="doc.name" :size="36" rounded/>
              <div class="ms-3">
                <div>{{ doc.name }}</div>
                <div class="text-muted">{{ doc.email }}</div>
              </div>
            </div>
            <div class="text-muted ms-2">
              <multiselect v-model="doc.loanToAssign"
                           label="name"
                           track-by="id"
                           @select="selectLoan($event, index)"
                           :options="loans"
                           :searchable="true"
                           :close-on-select="true"
                           :show-labels="false"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button class="btn btn-primary px-5 me-2" type="button"
                @click="assignToLoan(selectedItems[0])">Assign to Loan</button>
        <button class="btn btn-cancel px-5" type="button" @click="discard(false)">Discard</button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "AssignDocumentsModal",
  props: { docs: { type: Array, required: false } },
  components: {Multiselect},
  data() {
    return {
      loading: false,
      selectedItems: [],
      loans: [],
      loanToAssign: null
    }
  },
  methods: {
    isSelected(obj) {
      return this.selectedItems.indexOf(obj) !== -1
    },
    toggleCheckboxItem(item) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id)
      } else {
        this.selectedItems.push(item)
      }
    },
    toggleCheckboxAllItem() {
      if (this.selectedItems.length > 0) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.docs
      }
    },
    assignToLoan(doc) {
      this.$http.post(`/api/v1/secure_inbox/assign/${doc.loanToAssign.id}`, {
        documentIds: this.getDocumentIds(doc)
      })
        .then(() => {
          this.selectedItems.shift()

          this.selectedItems.length > 0
            ? this.assignToLoan(this.selectedItems[0])
            : this.discard(false)
        })
        .catch()
    },
    getLoans() {
      this.$http.get(`/api/v1/form/loans`)
        .then((res) => this.loans = res.data)
    },
    getDocumentIds() {
      let ids = [];

      this.selectedItems.map((item) => {
        for (const [, docs] of Object.entries(item.docs)) {
          docs.forEach((doc) => {
            ids.push(doc.id)
          })
        }
      })

      return ids
    },
    selectLoan(option, index) {
      this.selectedItems[index].loanToAssign = option
      this.refreshSelectedItems()
    },
    refreshSelectedItems() {
      this.selectedItems = this.selectedItems.filter((item) => {
        return item
      })
    },
    discard(updateList = false) {
      this.$emit('close', {
        propertyName: 'isOpenAssignDocumentsModal',
        updateList: updateList
      })
    }
  },
  beforeMount() {
    this.getLoans()
    this.toggleCheckboxAllItem()
    this.selectedItems = this.docs
    this.selectedItems.map((item) => {
      item.loanToAssign = null
    })
  }
}
</script>
<style type="scss" scoped>

.modal .modal-body-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
</style>
