<template>
  <div class="modal">
    <div class="modal-bg" @click="close(false, false)"></div>
    <div class="modal-block">
      <div class="modal-header d-block">
        <div class="d-flex justify-content-between align-items-center">
          <h3>View Documents</h3>
          <img src="@/assets/icons/archive.svg" class="icon" alt="Archive"
               @click="archiveDoc"
               content="Archive"
               v-tippy="{arrow: true, placement: 'left-center'}">
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="d-flex align-items-center">
            <div class="ms-2 me-3">
              <v-avatar :username="doc.name" rounded/>
            </div>
            <div>
              <div>{{ doc.name }}</div>
              <div class="text-muted">{{ doc.email }}</div>
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div :class="[ isForwardToAgent ? '' : 'ml-3' ]">
            <div v-if="isForwardToAgent" class="d-flex align-items-center">
              <multiselect v-model="agentToForward"
                           label="fullName"
                           track-by="id"
                           :options="agents"
                           :searchable="true"
                           :close-on-select="true"
                           :show-labels="false"/>
              <base-button title="Forward" action="secondary-default" :disabled="!agentToForward" @click-btn="forwardToAgent" class="ms-2" />
              <base-button title="Discard" action="secondary" @click-btn="$emit('toggleForwardToAgent')" class="ms-2" />
            </div>
            <div v-else class="d-flex align-items-center justify-content-between">
              <div class="text-muted">Loan Agent</div>
              <div>{{ doc.agent.fullName }}</div>
              <img src="@/assets/icons/forward.svg" class="icon me-2" alt="Edit"
                   @click="$emit('toggleForwardToAgent')"
                   content="Forward"
                   v-tippy="{arrow: true, placement: 'left-center'}">
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div v-if="isAssignToLoan" class="d-flex align-items-center">
            <multiselect v-model="loanToAssign"
                         label="name"
                         track-by="id"
                         :options="loans"
                         :searchable="true"
                         :close-on-select="true"
                         :show-labels="false"/>
            <base-button title="Assign" action="secondary-default" :disabled="!loanToAssign" @click-btn="assignToLoan" class="ms-2" />
            <base-button title="Discard" action="secondary" @click-btn="$emit('toggleAssignToLoan')" class="ms-2" />
          </div>
          <div v-else class="d-flex align-items-center justify-content-start ml-3 me-2">
            <img src="@/assets/icons/assign-blue.svg" class="opacity-05 me-2" alt="Edit">
            <div class="text-muted text-blue cursor-pointer" @click="$emit('toggleAssignToLoan')">Assign to Loan</div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Documents</div>
          <div v-if="!Array.isArray(doc.docs)">
            <div v-for="(key, indexDate) in Object.keys(doc.docs)" :key="indexDate" class="mt-3">
              <div class="d-flex align-items-center justify-content-between" v-if="doc.docs[key].length">
                <div class="text-muted">{{ key }}</div>
                <div class="text-blue cursor-pointer" @click="downloadAll()">
                  Download ({{ doc.docs[key].length }})
                </div>
              </div>
              <div class="doc-item mt-2" v-for="(file, index) in doc.docs[key]" :key="index">
                <div class="d-flex align-items-center justify-content-between">
                  <img src="@/assets/icons/attach.svg" class="opacity-05 mx-2" alt="Edit">
                  <span>{{ file.name }}</span>
                </div>
                <div>
                  <img src="@/assets/icons/delete.svg" class="icon" alt="Edit" @click="deleteFile(file.id)"
                       content="Delete file"
                       v-tippy="{arrow: true, placement: 'left-center'}">
                  <img src="@/assets/icons/download.svg" class="icon" alt="Edit" @click="downloadFile(file.id)"
                       content="Download"
                       v-tippy="{arrow: true, placement: 'left-center'}">
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="doc-item mt-2" v-for="(file, index) in doc.docs" :key="index">
              <div class="d-flex align-items-center justify-content-between">
                <img src="@/assets/icons/attach.svg" class="opacity-05 mx-2" alt="Edit">
                <span>{{ file.name }}</span>
              </div>
              <div v-if="isReceivedDocument(file)">
                <img src="@/assets/icons/delete.svg" class="icon" alt="Edit" @click="deleteFile(isReceivedDocument(file).id)"
                     content="Delete file"
                     v-tippy="{arrow: true, placement: 'left-center'}">
                <img src="@/assets/icons/download.svg" class="icon" alt="Edit" @click="downloadFile(isReceivedDocument(file).id)"
                     content="Download"
                     v-tippy="{arrow: true, placement: 'left-center'}">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="d-flex">
          <base-button v-if="activeTab !== 'requested'" title="Download all" action="secondary-default" @click-btn="downloadAll()" />
          <base-button title="Request Documents" action="secondary" @click-btn="close(false, true)" class="ms-2" />
          <base-button title="Cancel" action="temp" @click-btn="close(false, false)" class="ms-2" />
        </div>
        <img src="@/assets/icons/delete.svg" class="icon me-2" alt="Edit"
             @click="deleteDoc"
             content="Delete"
             v-tippy="{arrow: true, placement: 'left-center'}">
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import BaseButton from '../../components/BaseButton.vue'
export default {
  name: "ViewDocumentsModal",
  props: {
    doc: { type: Object, required: true },
    isForwardToAgent: { type: Boolean, required: false, default: false },
    isAssignToLoan: { type: Boolean, required: false, default: false },
    activeTab: { type: String, required: false, default: 'inbox' },
  },
  components: {Multiselect, BaseButton},
  data() {
    return {
      loading: false,
      forwardToAgentMode: false,
      assignToLoanMode: false,
      agents: [],
      agentToForward: null,
      loans: [],
      loanToAssign: null
    }
  },
  methods: {
    getAgents() {
      this.$http.get('/api/v1/form/agents-active')
        .then((res) => this.agents = res.data)
    },
    getLoans() {
      this.$http.get(`/api/v1/form/loans`)
        .then((res) => this.loans = res.data)
    },
    forwardToAgent() {
      this.$http.post(`/api/v1/secure_inbox/forward/${this.doc.id}`, { agentId: this.agentToForward.id })
        .then(() => {
          this.doc.agent = this.agentToForward
          this.$emit('toggleForwardToAgent')
        })
    },
    assignToLoan() {
      this.$http.post(`/api/v1/secure_inbox/assign/${this.loanToAssign.id}`, {
        documentIds: this.getDocumentIds()
      })
        .then(() => this.close(true))
        .catch()
    },
    downloadFile(id) {
      this.goto(this.backendUrl('/mlo/secure_inbox/download_single_document/' + id))
    },
    deleteFile(id) {
      const deleteConfirmation = confirm('Are you sure?');

      if (deleteConfirmation) {
        this.$http.delete(`/api/v1/secure_inbox/delete_document/${id}`)
          .then(() => {
            Object.keys(this.doc.docs).forEach((key) => {
              this.doc.docs[key] = this.doc.docs[key].filter((item) => {
                return item.id !== id
              })
            })
          })
          .catch()
      }
    },
    downloadAll() {
      let queryParams = new URLSearchParams({});
      queryParams.append('ids[]', this.doc.id)

      this.goto(this.backendUrl('/mlo/secure_inbox/download_archive?' + queryParams.toString()))
    },
    deleteDoc() {
      if (confirm('Delete document?')) {
        this.$http.delete(`/api/v1/secure_inbox/delete/${this.doc.id}`)
          .then(() => this.close(true))
          .catch()
      }
    },
    archiveDoc() {
      if (confirm('Archive document?')) {
        this.$http.get(`/api/v1/secure_inbox/archive/${this.doc.id}`)
          .then(() => this.close(true))
          .catch()
      }
    },
    getDocumentIds() {
      let ids = [];

      try {
        for (const [, docs] of Object.entries(this.doc.docs)) {
          docs.forEach((doc) => {
            ids.push(doc.id)
          })
        }
      } catch (e) {
        ids = []
        this.doc.docs.forEach((doc) => {
          let dc = this.isReceivedDocument(doc)
          if (dc) {
            ids.push(dc.id)
          }
        })
      }

      return ids
    },
    close(updateList = false, requestDocument = false) {
      this.$emit('close', updateList, requestDocument)
    },
    isReceivedDocument(file) {
      const received = this.doc.receivedDocs.filter(el => {
        if (el.name.toLowerCase().includes(file.name.toLowerCase())) {
          return el
        }
      });
      return received[0];
    }
  },
  beforeMount() {
    this.getAgents()
    this.getLoans()
    this.forwardToAgentMode = this.isForwardToAgent
    this.assignToLoanMode = this.isAssignToLoan
    this.agentToForward = this.doc.agent
  },
}
</script>
<style type="scss" scoped>
.modal .modal-body-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.action-btn {
  width: 100%;
  max-width: 140px;
  max-height: 44px;
  height: 44px;
}
.doc-item {
  min-height: 48px;
}
</style>
