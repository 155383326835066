S<template>
  <div>
    <div class="controls-panel d-flex justify-content-between align-items-center px-3">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <div @click="toggleCheckboxAllItem">
            <img src="@/assets/icons/bold-checkbox-checked.svg" alt="download" class="icon ms-2 me-2 opacity-05"
                 v-if="selectedItems.length >= pagination.perPage"
                 content="Selected"
                 v-tippy="{arrow: true, placement: 'top-center'}">
            <img src="@/assets/icons/bold-checkbox-partial-selected.svg" alt="download"
                 class="icon ms-2 me-2 opacity-05"
                 v-else-if="selectedItems.length < pagination.perPage && selectedItems.length !== 0"
                 content="Selected"
                 v-tippy="{arrow: true, placement: 'top-center'}">
            <img src="@/assets/icons/bold-checkbox-unchecked.svg" alt="download" class="icon ms-2 me-2 opacity-05"
                 v-else
                 content="Selected"
                 v-tippy="{arrow: true, placement: 'top-center'}">
          </div>
          <img src="@/assets/icons/bold-download.svg" alt="Download" class="icon me-2 opacity-05"
               :class="{ disabled: isDisabled }"
               @click="openModal('isOpenDownloadDocumentsModal')"
               v-if="!requestedTab"
               content="Download selected documents"
               v-tippy="{arrow: true, placement: 'top-center'}">
          <img src="@/assets/icons/bold-forward.svg" alt="forward" class="icon me-2 opacity-05"
               :class="{ disabled: isDisabled }"
               @click="openModal('isOpenForwardDocumentsModal')"
               v-if="!requestedTab"
               content="Forward selected documents"
               v-tippy="{arrow: true, placement: 'top-center'}">
          <img src="@/assets/icons/bold-assign.svg" alt="assign" class="icon me-2 opacity-05"
               :class="{ disabled: isDisabled }"
               @click="openModal('isOpenAssignDocumentsModal')"
               v-if="!requestedTab"
               content="Assign selected documents"
               v-tippy="{arrow: true, placement: 'top-center'}">

          <div class="icon-vl" v-if="!requestedTab"></div>

          <img src="@/assets/icons/bold-archive.svg" alt="archive" class="icon mx-2 opacity-05"
               :class="{ disabled: isDisabled }"
               @click="openModal('isOpenArchiveDocumentsModal')"
               v-if="!requestedTab"
               content="Archive selected documents"
               v-tippy="{arrow: true, placement: 'top-center'}">
          <img src="@/assets/icons/bold-delete.svg" alt="delete" class="icon me-2 opacity-05"
               :class="{ disabled: isDisabled }"
               @click="openModal('isOpenDeleteDocumentsModal')"
               content="Delete selected documents"
               v-tippy="{arrow: true, placement: 'top-center'}">

          <div class="icon-vl" v-if="!requestedTab"></div>
        </div>
        <base-button title="Mark Unread" action="secondary" size="sm" class="ms-2" v-if="inboxTab || otherTab" @click-btn="markAllUnread" :disabled="selectedItems.length === 0"/>
        <base-button title="Move back" action="secondary-default" :loading="ui.movingBack"
                     :disabled="!itemsSelected || ui.movingBack" @click-btn="moveBack(selectedItems, false)"
                     v-if="tabName === 'archived'" class="ms-2" size="sm" />
      </div>
      <Pagination :from="pagination.from"
                  :to="pagination.to"
                  :total="pagination.total"
                  :page="pagination.page"
                  :per-page="pagination.perPage"
                  @update="getDocuments"/>
    </div>
    <div class="page-content p-normal">
      <div class="d-flex justify-content-between table-filter">
        <div class="search-wrapper">
          <img src="@/assets/icons/icon-search.svg" alt="Icon search">
          <input type="text"
                 v-model="query"
                 @keyup="search"
                 placeholder="Search"
                 aria-label="=Search">
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <multiselect v-model="sortAgent"
                       v-if="isGranted('ROLE_BRANCH_MANAGER') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_LOA')"
                       track-by="id" label="fullName" id="fieldSortByAgent"
                       class="full-width custom-select me-2"
                       open-direction="bottom"
                       :options="agents"
                       :searchable="true"
                       :close-on-select="true"
                       :show-labels="false"
                       @select="sortByAgent"
                       placeholder="Sort by agent">
          </multiselect>
          <multiselect v-model="sortOption"
                       track-by="value"
                       label="label"
                       id="fieldSort"
                       class="full-width custom-select"
                       open-direction="bottom"
                       :options="sortOptions"
                       :searchable="true"
                       :close-on-select="true"
                       :show-labels="false"
                       :allow-empty="false"
                       @select="sortBy"
                       placeholder="Sort by">
          </multiselect>
        </div>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-else>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Borrower</th>
            <th scope="col">Agent</th>
            <th scope="col">Documents</th>
            <th scope="col">
              <span v-if="requestedTab">Requested on</span>
              <span v-else>Created Date</span>
            </th>
            <th scope="col">
              <span>Last Activity</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(doc, index) in documents" :key="index" :class="!doc.readAt ? 'unreadBgColor' : 'readBgColor'">
            <td>
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/bold-checkbox-unchecked.svg" alt="download" class="icon ms-2 me-2 opacity-05"
                     @click="toggleCheckboxItem(doc, index)" v-if="!isSelected(doc)">
                <img src="@/assets/icons/bold-checkbox-checked.svg" alt="download" class="icon ms-2 me-2 opacity-05"
                     @click="toggleCheckboxItem(doc)" v-else>
                <div class="ms-2 me-3">
                  <v-avatar :username="doc.name" rounded/>
                </div>
                <div>
                  <div>{{ doc.name }}</div>
                  <div class="text-muted">{{ doc.email }}</div>
                </div>
              </div>
            </td>
            <td>{{ doc.agent.fullName }}</td>
            <td>
              <base-button :title="docsButtonTitle(doc)" action="secondary" size="sm" style="min-width: 130px"
                           @click-btn="openViewDocumentsModal(doc)" />
            </td>
            <td>
              {{ doc.createdAt }}
            </td>
            <td width="20%">
              <div :class="[ requestedTab ? '' : 'createdAt' ]">{{ doc.updatedAt }}</div>
              <div class="actions" v-if="tabName === 'inbox' || tabName === 'other'">
                <img src="@/assets/icons/bold-download.svg" alt="download" class="icon me-2 opacity-05"
                     v-if="doc.isGranted['view']"
                     @click="openViewDocumentsModal(doc)"
                     content="Download"
                     v-tippy="{arrow: true, placement: 'top-center'}">
                <img src="@/assets/icons/bold-forward.svg" alt="forward" class="icon me-2 opacity-05"
                     v-if="doc.isGranted['forward']"
                     @click="openViewDocumentsModal(doc, true)"
                     content="Forward"
                     v-tippy="{arrow: true, placement: 'top-center'}">
                <img src="@/assets/icons/bold-assign.svg" alt="assign" class="icon me-2 opacity-05"
                     @click="openViewDocumentsModal(doc, false, true)"
                     content="Assign"
                     v-tippy="{arrow: true, placement: 'top-center'}">
                <img src="@/assets/icons/bold-archive.svg" alt="archive" class="icon me-2 opacity-05"
                     v-if="doc.isGranted['archive']"
                     @click="archive(doc)"
                     content="Archive"
                     v-tippy="{arrow: true, placement: 'top-center'}">
              </div>
              <base-button title="Move back" action="secondary-default" class="mtb"
                           size="sm" @click-btn="moveBack(doc.id)" v-if="tabName === 'archived'" />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <transition name="slide-fade">
      <RequestDocumentsModal v-if="isOpenRequestDocumentsModal" @close="closeModal"/>

      <DownloadDocumentsModal v-if="isOpenDownloadDocumentsModal"
                              :docs="selectedItems"
                              @close="closeModal"/>

      <ForwardDocumentsModal v-if="isOpenForwardDocumentsModal"
                             :docs="selectedItems"
                             @close="closeModal"/>

      <AssignDocumentsModal v-if="isOpenAssignDocumentsModal"
                            :docs="selectedItems"
                            @close="closeModal"/>

      <ArchiveDocumentsModal v-if="isOpenArchiveDocumentsModal"
                             :docs="selectedItems"
                             @close="closeModal"/>

      <DeleteDocumentsModal v-if="isOpenDeleteDocumentsModal"
                            :docs="selectedItems"
                            :tab-name="tabName"
                            @close="closeModal"/>

      <ViewDocumentsModal v-if="viewDocumentsModal.open && viewDocumentsModal.document"
                          :doc="viewDocumentsModal.document"
                          :is-forward-to-agent="viewDocumentsModal.forward"
                          :is-assign-to-loan="viewDocumentsModal.assign"
                          :active-tab="tabName"
                          @toggleForwardToAgent="viewDocumentsModal.forward = !viewDocumentsModal.forward"
                          @toggleAssignToLoan="viewDocumentsModal.assign = !viewDocumentsModal.assign"
                          @close="closeViewDocumentsModal"/>
    </transition>
  </div>
</template>

<script>
import DownloadDocumentsModal from './DownloadDocumentsModal'
import ForwardDocumentsModal from './ForwardDocumentsModal'
import AssignDocumentsModal from './AssignDocumentsModal'
import ArchiveDocumentsModal from './ArchiveDocumentsModal'
import DeleteDocumentsModal from './DeleteDocumentsModal'
import ViewDocumentsModal from './ViewDocumentsModal'
import RequestDocumentsModal from './RequestDocumentsModal'
import Pagination from '../../components/Pagination'
import Multiselect from 'vue-multiselect';
import BaseButton from '../../components/BaseButton.vue'

export default {
  name: "BaseTab",
  components: {
    DownloadDocumentsModal,
    ForwardDocumentsModal,
    AssignDocumentsModal,
    ArchiveDocumentsModal,
    DeleteDocumentsModal,
    ViewDocumentsModal,
    RequestDocumentsModal,
    Pagination,
    Multiselect,
    BaseButton
  },
  props: {
    tabName: {required: true, type: String, default: 'none'},
    requestDocs: { required: false, type: Boolean },
    agents: { required: true, type: Array }
  },
  data() {
    return {
      ui: {
        loading: false,
        movingBack: false
      },
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        page: 1,
        perPage: 10
      },
      documents: [],
      selectedItems: [],
      viewDocumentsModal: {
        open: false,
        document: null,
        forward: false,
        assign: false,
      },
      isOpenRequestDocumentsModal: false,
      isOpenDownloadDocumentsModal: false,
      isOpenForwardDocumentsModal: false,
      isOpenAssignDocumentsModal: false,
      isOpenArchiveDocumentsModal: false,
      isOpenDeleteDocumentsModal: false,
      timer: null,
      query: '',
      sortOption: {value: 'updatedAt_DESC', label: 'Last Activity DESC'},
      sortOptions: [
        {value: 'createdAt_ASC', label: 'Created Date ASC'},
        {value: 'createdAt_DESC', label: 'Created Date DESC'},
        {value: 'updatedAt_ASC', label: 'Last Activity ASC'},
        {value: 'updatedAt_DESC', label: 'Last Activity DESC'}
      ],
      sortAgent: null,
    }
  },
  methods: {
    isSelected(obj) {
      return this.selectedItems.indexOf(obj) !== -1
    },
    toggleCheckboxItem(item) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id)
      } else {
        this.selectedItems.push(item)
      }
    },
    toggleCheckboxAllItem() {
      this.selectedItems = this.itemsSelected ? [] : this.documents
    },
    openModal(propertyName) {
      this[propertyName] = this.itemsSelected
      this.markAllRead()
    },
    closeModal(data) {
      this[data.propertyName] = false
      this.selectedItems = []
      if (data.updateList) {
        this.getDocuments()
      }
    },
    openViewDocumentsModal(doc, forward, assign) {
      this.viewDocumentsModal.document = doc
      if (!this.requestedTab) {
        this.viewDocumentsModal.forward = forward
        this.viewDocumentsModal.assign = assign
      }
      this.viewDocumentsModal.open = true
      this.checkMarkRead(doc)
    },
    closeViewDocumentsModal(updateList, newRequestDocument) {
      this.viewDocumentsModal.document = null
      this.viewDocumentsModal.forward = false
      this.viewDocumentsModal.assign = false
      this.viewDocumentsModal.open = false
      if (updateList) {
        this.getDocuments()
      }
      if (newRequestDocument) {
        this.$emit('openModal')
      }
    },
    checkMarkRead(doc) {
      if (doc.readAt == null) {
        this.markSingleRead(doc.id);
      }
    },
    getDocuments(pagination) {
      let pag = pagination ? pagination : this.pagination
      this.ui.loading = true;
      this.$http.get(`/api/v1/secure_inbox/${this.tabName}`, {
          params: {
            query: this.query,
            sortBy: this.sortOption.value,
            sortByAgent: this.sortAgent ? this.sortAgent.id : null,
            page: pag.perPage !== this.pagination.perPage ? 1 : pag.page,
            perPage: pag.perPage
          }
        })
        .then((res) => {
          this.documents = res.data.documents
          this.sortAgent = res.data.selectedAgent
          this.pagination = res.data.pagination
          this.ui.loading = false
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
          this.ui.loading = false;
        })
    },
    search() {
      this.ui.loading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => { this.getDocuments() }, 800);
    },
    markSingleRead(id) {
      this.$http.get(`/api/v1/secure_inbox/read/${id}`)
        .then((res) => {
          var counters = this.$store.state.counters
          counters.receivedDocuments = res.data
          this.$store.commit("setCounters", counters);
           this.documents.map((document) => {
            if (document.id === id) {
              document.readAt = this.formatDate('', 'YYYY-MM-DD HH:mm:ss')
            }
            return document;
          });
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
    },
    markAllRead() {
      let formData = new FormData();
      let items = [];
      this.selectedItems.forEach(item => {
        items.push(item.id)
        formData.append('messages[]', item.id);
      });

      this.$http.post(`/api/v1/secure_inbox/reads`, formData)
        .then((res) => {
          var counters = this.$store.state.counters
          counters.receivedDocuments = res.data
          this.$store.commit("setCounters", counters);
          this.documents.map((document) => {
            if (items.includes(document.id)) {
              document.readAt = this.formatDate('', 'YYYY-MM-DD HH:mm:ss')
            }
            return document;
          });
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
    },
    markAllUnread() {
      console.log('todo Mark unread')
      let formData = new FormData();
      this.selectedItems.forEach(item => {
        formData.append('messages[]', item.id);
      });
      this.$http.post(`/api/v1/secure_inbox/unreads`, formData)
        .then((res) => {
          var counters = this.$store.state.counters
          counters.receivedDocuments = res.data
          this.$store.commit("setCounters", counters);
          this.getDocuments()
          this.selectedItems = [];
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
    },
    moveBack(id, oneItem = true) {
      this.ui.movingBack = true;
      if (!oneItem) {
        const promises = this.selectedItems.map((el) => {
          return this.$http.get(`/api/v1/secure_inbox/restore/${el.id}`)
        })

        Promise.allSettled(promises)
        .then(() => {
          this.getDocuments()
          this.ui.movingBack = false;
          this.selectedItems = [];
        })
        .catch((err) => { console.log(err) })
        .finally(() => this.ui.movingBack = false)
      } else {
        this.$http.get(`/api/v1/secure_inbox/restore/${id}`)
        .then(() => {
            this.getDocuments()
            this.ui.movingBack = false;
            this.selectedItems = [];
        })
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
      }
    },
    archive(doc) {
      this.$http.get(`/api/v1/secure_inbox/archive/${doc.id}`)
        .then(() => this.getDocuments())
        .catch(err => {
          this.pushAlert("error", err.response.statusText);
        })
    },
    sortBy(option) {
      this.sortOption = option
      this.getDocuments()
    },
    sortByAgent(option) {
      this.sortAgent = option
      this.getDocuments()
    },
    docsButtonTitle(doc) {
      const lastLetter =  ( doc.docsTotal > 1 || doc.docsTotal === 0 ) ? 's': ''
      return doc.docsTotal + ' Document' + lastLetter
    }
  },
  computed: {
    isDisabled() {
      return this.selectedItems.length === 0
    },
    itemsSelected() {
      return this.selectedItems.length > 0
    },
    inboxTab() {
      return this.tabName === 'inbox'
    },
    otherTab() {
      return this.tabName === 'other'
    },
    requestedTab() {
      return this.tabName === 'requested'
    }
  },
  watch: {
    tabName: function (newTabName, oldTabName) {
      if (newTabName !== oldTabName) {
        this.getDocuments()
        this.selectedItems = []
        this.query = ''
      }
    },
    requestDocs: function (val) {
      if (val) {
        this.isOpenRequestDocumentsModal = val
      }
    },
    sortAgent: function (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.$emit('updateStats', newVal)
      }
    }
  },
  beforeMount() {
    this.getDocuments()
  }
}
</script>
<style lang="scss" scoped>
table tr {
  vertical-align: middle;

  &.unreadBgColor {
    background-color: #F6F6F6 !important;
    font-weight: 600;
  }
  &.readBgColor {
    background-color: #ffffff !important;
  }
  .createdAt {
    display: block;
  }

  .actions {
    display: none;
  }

  .mtb {
    display: none;
  }

  .btn-docs {
    max-width: 130px;
    width: 100%;
    border: 1px solid rgba(212, 212, 212, 1);
    border-radius: 8px;
    opacity: 0.7;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  &:hover {
    background-color: #F6F6F6 !important;

    .doc {
      background-color: #dadada;
    }

    .createdAt {
      display: none;
    }

    .actions {
      display: block;
      img {
        &:hover {
          opacity: 1 !important;
        }
      }
    }

    .mtb {
      display: block;
    }
  }
}

.pagination {
  &::v-deep {
    .multiselect {
      &__single {
        width: fit-content;
      }

      &__select {
        padding: 0;
      }
    }
  }
}

.table-filter::v-deep {
  .multiselect {
    height: 36px;
    width: 200px;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
</style>
