<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <h3 class="page-header-title">Secure Inbox</h3>
      </div>
      <div class="page-header-buttons">
        <base-button type="button" title="Request Documents" action="primary" @click-btn="openModal" />
      </div>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ active: activeTab === 'inbox' }" @click="setActiveTab('inbox')">
          Inbox <span v-if="data.inbox.count > 0">({{ data.inbox.count }})</span>
        </li>
        <li :class="{ active: activeTab === 'requested' }" @click="setActiveTab('requested')">
          Requested <span v-if="data.requested.count > 0">({{ data.requested.count }})</span>
        </li>
        <li :class="{ active: activeTab === 'archived' }" @click="setActiveTab('archived')" v-if="data.archived.visible">
          Archived <span v-if="data.archived.count > 0">({{ data.archived.count }})</span>
        </li>
      </ul>
    </div>
    <transition name="component-fade" mode="out-in">
      <component v-bind:is="activeTab"
                 :tabName="activeTab"
                 :requestDocs="requestDocs"
                 :agents="agents"
                 @openModal="openModal"
                 @updateStats="getDocsCount"/>
    </transition>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';
import Inbox from './BaseTab';
import Other from './BaseTab';
import Requested from './BaseTab';
import Archived from './BaseTab';

export default {
  name: "SecureInbox",
  components: {Inbox, Other, Requested, Archived, BaseButton},
  data() {
    return {
      activeTab: 'inbox',
      requestDocs: false,
      data: {
        inbox: {count: 0, visible: true},
        requested: {count: 0, visible: true},
        archived: {count: 0, visible: true}
      },
      agents: []
    }
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName
    },
    getDocsCount(selectedAgent) {
      this.$http.get('/api/v1/secure_inbox', {
        params: {
          sortByAgent: selectedAgent ? selectedAgent.id : null
        }
      })
        .then((res) => {
          this.data = res.data
          this.agents = res.data.agents
        })
        .catch()
    },
    openModal() {
      if (!this.requestDocs) {
        this.requestDocs = true
        setTimeout(() => {
          this.requestDocs = false
        }, 100)
      }
    },
  },
  beforeMount() {
    this.getDocsCount()
  }
}
</script>
